<template>
  <div class="flex">
    <span class="h3 mr-10 mb-0">
      <vz-icon
        :name="iconName"
        :variant="variant"
      />
    </span>
    <div class="flex-align-self-center">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import VzIcon from '~/components/ui-kit/vz-icon.vue';

const props = defineProps({
  variant: {
    type: String,
    default: 'primary'
  },
  outline: {
    type: Boolean,
    default: false
  }
})

const iconName = computed(() => {
  return props.outline ? 'information-outline' : 'alert-circle'
})
</script>
